import React, { useEffect } from 'react';
import "./HomePage.scss";
import Header from '../components/Header';
import Banner from '../components/Banner';
import Services from '../components/Services';
import Feedback from '../components/Feedback';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
    const { i18n } = useTranslation();

    const supportedLanguages = ['cs', 'da', 'de', 'en', 'es', 'fr', 'it', 'no'];


    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        const deviceLanguage = navigator.language.slice(0, 2);
        const languageToUse = supportedLanguages.includes(deviceLanguage) ? deviceLanguage : 'en';
        changeLanguage(languageToUse);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="home-page">
            <Header />
            <Banner />
            <Services />
            <Feedback />
        </div>
    );
};

export default HomePage